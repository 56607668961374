import axiosApi from '@/service/modules/axios-api'
import { AUDIT_SERVICE_PREFIX } from '@/service'
// 分页获取登录日志
export function getLoginLogPagination(data) {
  return axiosApi.post(AUDIT_SERVICE_PREFIX + '/loginLog/page', data)
}
// 分页获取操作日志
export function getOperateLogPagination(data) {
  return axiosApi.post(AUDIT_SERVICE_PREFIX + '/auditLog/page', data)
}
// 备份
export function backupOperateLog() {
  return axiosApi.get(AUDIT_SERVICE_PREFIX + '/auditLog/backupAuditLog')
}

export function resumeOperateLog() {
  return axiosApi.get(AUDIT_SERVICE_PREFIX + '/auditLog/resumeAuditLog')
}
